import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { ArticleHeader } from './components/ArticleHeader';
import { StrapiArticle } from '../../models/article';
import { Grid } from '../../components/Grid';
import { ArticleContent } from './components/ArticleContent/ArticleContent.component';
import { ArticleSide } from './components/ArticleSide/ArticleSide.component';
import { useWindow } from '../../store/state/window/window.state';
import { formatToIso } from '../../helpers/date.helper';

import avatarDawid from '../../images/author_dawid.jpg';
import avatarMichal from '../../images/author_michal.jpg';

interface ArticleTemplateEdge {
  node: {
    slug: string;
    title: string;
  }
}

interface ArticleTemplateProps {
  pageContext: {
    slug: string;
    edges: ArticleTemplateEdge[];
  };
  data: {
    strapiArticle: StrapiArticle,
  };
}

export const query = graphql`
  query($slug: String) {
    strapiArticle(slug: { eq: $slug }) {
      author {
        name
        title
      }
      content {
        data {
          content
        }
      }
      image {
        url
      }
      published
      publishedAt
      reading_time
      title
    }
  }`;

const getSideNavElements = (edges: ArticleTemplateEdge[]) => (
  edges.map((edge) => ({
    title: edge.node.title,
    slug: edge.node.slug,
  }))
);

const ArticleTemplate: React.FC<ArticleTemplateProps> = ({
  data: { strapiArticle: {
    author,
    content,
    image,
    published,
    reading_time,
    title,
  }},
  pageContext,
}) => {
  const { state: { isMobile, isTablet, resolution } } = useWindow();

  const avatar = author?.name === 'Dawid Rożenek'
    ? avatarDawid
    : avatarMichal

  return (
  <>
    <article className={'article'}>
      <Helmet>
        <meta charSet={'utf-8'} />
        <title>{title}</title>
      </Helmet>
      <ArticleHeader
        publishedAt={formatToIso(published)}
        image={image}
        reading_time={reading_time}
        title={title}
        isColumn={isMobile || isTablet}
      />
      <Grid.Row
        align={'start'}
        direction={isMobile ? 'column-reverse' : 'row'}
      >
        <Grid.Column
          mobile={12}
          tablet={5}
          desktop={3}
        >
          <ArticleSide
            author={{
              avatarUrl: author?.avatar?.url || avatar,
              name: author?.name,
              position: author?.title,
            }}
            sideNavElements={getSideNavElements(pageContext.edges)}
          />
        </Grid.Column>
        <Grid.Column
          mobile={12}
          tablet={7}
          desktop={9}
        >
          <ArticleContent content={content.data.content} />
        </Grid.Column>
      </Grid.Row>
    </article>
  </>
);
          };

export default ArticleTemplate;