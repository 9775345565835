import React from 'react';
import { Author, AuthorProps } from '../../../../components/Author/Author.component';
import { SideMenu } from '../../../../components/SideMenu/SideMenu.component';

import './ArticleSide.component.scss';

interface ArticleSideProps {
  author?: AuthorProps;
  sideNavElements: {
    title: string;
    slug: string;
  }[];
}

export const ArticleSide: React.FC<ArticleSideProps> = ({ author, sideNavElements }) => (
  <div className={'article-side'}>
    <div className={'article-side__content'}>
      {author && (
        <Author
          avatarUrl={author.avatarUrl}
          name={author.name}
          position={author.position}
        />
      )}
      <SideMenu
        elements={sideNavElements}
      />
    </div>
  </div>
);