import React from 'react';
import ReactMarkdown from 'react-markdown';
import Prism from 'prismjs';
import "prismjs/components/prism-markup-templating.js"

import { TextFontSize } from '../../../../components/Text';

import './ArticleContent.component.scss';

interface ArticleContentProps {
  content: string;
}

export const ArticleContent: React.FC<ArticleContentProps> = ({ content }) => {
  
  React.useEffect(() => {
    Prism.highlightAll()
  })
  
  return (
    <div
      className={'article-content'}
      style={{
        '--article-content-header-size': TextFontSize.huge,
        '--article-content-paragraph-size': TextFontSize.regular,
      } as React.CSSProperties}
    >
      <ReactMarkdown
        className={"language-js"}
        children={content}
        transformImageUri={url => url.startsWith('http') ? url : `${process.env.API_URL}${url}`}
      />
    </div>
  );
};
