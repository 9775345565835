import React from 'react';
import { Link } from 'gatsby';

import './SideMenu.component.scss';
import { getArticleLink } from '../../helpers/link.helper';

interface SideMenuProps {
  elements: {
    title: string;
    slug: string;
  }[];
}

export const SideMenu: React.FC<SideMenuProps> = ({ elements }) => (
  <nav className={'side-menu'}>
    <ul className={'side-menu__list'}>
      {elements.map(element => (
        <Link
          key={`side-menu__list-${element.slug}`}
          to={getArticleLink(element.slug)}
        >
          <li className={'side-menu__item'}>
            {element.title}
          </li>
        </Link>
      ))}
    </ul>
  </nav>
);