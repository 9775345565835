import Image from 'gatsby-image';
import React from 'react';

import { Grid } from '../../../../components/Grid';
import { Text, TextType, TextFontSize, TextFontWeight } from '../../../../components/Text';
import { Color } from '../../../../models/color';
import { StrapiArticle } from '../../../../models/article';

import './ArticleHeader.component.scss';

type ArticleHeaderProps =
  & Pick<StrapiArticle, 'image' | 'publishedAt' | 'reading_time' | 'title'>
  & {
    isColumn: boolean;
  };

export const ArticleHeader: React.FC<ArticleHeaderProps> = ({
  image,
  publishedAt,
  reading_time,
  title,
  isColumn,
}) => (
  <div className={'article-header'}>
    <Grid.Row
      direction={isColumn ? 'column-reverse' : 'row'}
    >
      <Grid.Column
        mobile={12}
        tablet={12}
        desktop={3}
      >
        <div className={'article-header__title-wrapper'}>
          <div className={'article-header__title'}>
            <Text
              type={TextType.H1}
              color={Color.MINT}
              size={TextFontSize.max}
              weight={TextFontWeight.bold}
            >
              {title}
            </Text>
          </div>
          <Grid.Row
            justify={'space-around'}
          >
            <Text
              size={TextFontSize.small}
            >
              {publishedAt}
            </Text>
            <Text
              size={TextFontSize.small}
            >
              {`${reading_time} min`}
            </Text>
          </Grid.Row>
        </div>
      </Grid.Column>
      <Grid.Column
        mobile={12}
        tablet={12}
        desktop={9}
      >
        <img
          className={'article-header__image'}
          src={image.url}
          alt={title}
        />
      </Grid.Column>
    </Grid.Row>
  </div>
);