import React from 'react';

import { Text, TextFontSize } from '../Text';

import './Author.component.scss';

export interface AuthorProps {
  avatarUrl: string;
  name: string;
  position: string;
}

export const Author: React.FC<AuthorProps> = ({
  avatarUrl,
  name,
  position,
}) => (
  <div className={'author'}>
    <img
      className={'author__avatar'}
      src={avatarUrl}
    />
    <Text
      size={TextFontSize.big}
    >
      {name}
    </Text>
    <Text
      size={TextFontSize.small}
    >
      {position}
    </Text>
  </div>
);